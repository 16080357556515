import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable, of} from "rxjs";
import {environment} from "@env/environment";
import { map, catchError, tap } from 'rxjs/operators';
const cpt_path = environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment';

@Injectable({
  providedIn: 'root'
})

export class NewBillService {

  constructor(private http: HttpClient) { }
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }


  getVisitListByDate(opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getVisitListByDate', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getVisitListByDate'))
      ));
  }

  getIncoiceList(opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/listinvoices', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getIncoiceList'))
      ));
  }
  saveInvestigationByCashier (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/saveInvestigationByCashier', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('saveInvestigationByCashier'))
      ));
  }
  deleteInvestigationByCashier(opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/deleteInvestigationByCashier', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('deleteInvestigationByCashier'))
    ));
  }
  get_lab_investigation (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getLabInvestigation', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('get_lab_investigation'))
      ));
  }
  getBillByAssessment (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getBillByAssessment', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getBillByAssessment'))
      ));
  }
  getInvoiceDetails (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getBill', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getInvoiceDetails'))
      ));
  }
  getPatientDetails (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getPatientDetails', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getPatientDetails'))
      ));
  }
  savePatientBill (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/savePatientBill', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('savePatientBill'))
    ));
  }
  finalizePatientBill (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/finalizePatientBill', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('finalizePatientBill'))
    ));
  }
  getPendingAmount (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getPendingAmount', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPendingAmount'))
    ));
  }
  getBillDetails (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getBill', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getBillDetails'))
    ));
  }

  updateBilling (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/updateBilling', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('updateBilling'))
    ));
  }

  getReceiptsByPatient (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getReceiptsByPatient', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getReceiptsByPatient'))
    ));
  }
  getReceiptsList (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getReceiptsList', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getReceiptsList'))
    ));
  }

  getReceiptDetails (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getReceiptDetails', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getReceiptDetails'))
    ));
  }
  editReceipt (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/editReceipt', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('editReceipt'))
    ));
  }


  getReceiptsDetails (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getReceiptsDetails', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getReceiptsDetails'))
    ));
  }


  invoiceWriteOff (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/invoiceWriteOff', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('invoiceWriteOff'))
    ));
  }
  finalizeReceipt (opData): Observable<any> {
    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/finalizeReceipt', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('finalizeReceipt'))
    ));
  }
  getInsurancePayerList(term: string) {
    if (term === '') {
      return of([]);
    }
    return this.http
      .post<any>(environment.API_ENDPOINT + 'Querys/getInsurancePayerList', JSON.stringify( {search_text:  term, limit: 50, start: 1})).pipe(
        map(response => response['data'])
      );
  }

  getNetworkList(term: string) {
    if (term === '') {
      return of([]);
    }
    return this.http
      .post<any>(environment.API_ENDPOINT + 'Querys/getNetworkList', JSON.stringify( {search_text:  term, limit: 50, start: 1})).pipe(
        map(response => response['data'])
      );
  }

  getTpaList(term: string) {
    if (term === '') {
      return of([]);
    }
    return this.http
      .post<any>(environment.API_ENDPOINT + 'Querys/getTpaList', JSON.stringify( {search_text:  term, limit: 50, start: 1})).pipe(
        map(response => response['data'])
      );
  }
  saveLabInvestigationResults (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/saveLabInvestigationResults', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('saveLabInvestigationResults'))
    ));
  }
  getLabInvestigationResults (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getLabInvestigationResults', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getLabInvestigationResults'))
    ));
  }
  getPatientCptRate (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getPatientCptRate', JSON.stringify(opData)).pipe(
      tap((result) =>
      catchError(this.handleError<any>('getPatientCptRate'))
    ));
  }
  checkPreviousBill (opData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/checkPreviousBill', JSON.stringify(opData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('checkPreviousBill'))
      ));
  }
// save files
  saveCPTFiles (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/saveCPTFiles', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('postData'))
      ));
  }
  getCPTFiles (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/getCPTFiles', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('postData'))
      ));
  }

  getRefundReceiptListByDate (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'Refund/getRefundReceiptListByDate', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getRefundReceiptListByDate'))
      ));
  }
  getRefundReceiptDetails (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'Refund/getRefundReceiptDetails', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('getRefundReceiptDetails'))
      ));
  }

  removeCPTFiles (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'NewBill/removeCPTFiles', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('postData'))
      ));
  }

  saveRefundDetails (postData): Observable<any> {

    return this.http.post<any>(environment.API_ENDPOINT + 'Refund/saveRefundDetails', JSON.stringify(postData)).pipe(
      tap((result) =>
        catchError(this.handleError<any>('postData'))
      ));
  }

  cptsearch(term: string,department:number) {
    if (term === '') {
      return of([]);
    }


    return this.http
      .post<any>(cpt_path, JSON.stringify( {procedure_code_category : department , search_text:  term, limit: 50, current_procedural_code_id : '0', start: 1})).pipe(
        map(response => response['data'])
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
