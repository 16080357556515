export const environment = {
    production: true,
    API_ENDPOINT : 'http://alraas.medimate247.com/server/',
    // API_ENDPOINT : 'http://3.109.39.26/alras/server/',
  //   API_ENDPOINT : 'http://192.168.1.27/hms_acoc/server/',
    DOCUMENT_UPLOAD_PATH : 'public/uploads/documents/17/',
    UPLOAD_PATH : 'public/uploads/documents/',
    CONSENT_FILE_PATH : 'public/uploads/documents/consent/',
    LABRESULT_UPLOAD_PATH : 'public/uploads/labresult/',
    RADIOLOGY_UPLOAD_PATH : 'public/uploads/radiologyupload/',
    OP_ATTACHMENT_PATH : 'public/uploads/op_attachment/',
    FILE_UPLOAD_PATH : 'public/uploads/documents/37/',
    XML_FILE_PATH : 'public/xml/submission/',
    SELF_PAYMENT : '2',
    CORPORATE_CONSTANT : "3",
    NURSING_ASSESMENT : 1,
    NURSING_ASSESMENT_MENU : 4,
    DOCTOR_ASSESMENT_MENU : 12,
    DOCUMENT_UPLOAD : 17,
    DASHBOARD_MODULE_GROUP_ID : 7,
    EIDCARD : 1,
    TREATMENT_SUMMARY_ID : 13, // Treatment summary popup menu id
    ASSESSMENT_SUMMARY_ID : 14, // Assessment summary popup menu id
    ASSESSMENTS_ID : 15, // Assessments popup menu id
    VITALS_ID : 16, // Vitals popup menu id
    DOCUMENT_ID : 17, // Document popup menu id
    HEIGHT : 5, // TEST_PARAMETER_ID==5
    WEIGHT : 4, // TEST_PARAMETER_ID==4
    BMI : 6, // TEST_PARAMETER_ID==6
    BSA : 7, // TEST_PARAMETER_ID==7
    SPO2 : 10, // TEST_PARAMETER_ID==7
    DENTAL_DEPARTMENT_ID:30,
    OTHER_DOCUMENTS:93,
    OTHER_DOCUMENTS_DOCTOR:95,
    CONSENT_FORM:100,
    PROBLEM_MODULE:106,
    ALLERGIE_MODULE:103,
    HISTORY_MODULE:108,
    NURSING_ALLERGY_MODULE:109,
    OTHER_EMIRATES:45,
    DUBAI_EMIRATES:11,
    UAE_COUNTRY_CODE:218,
    EMIRATI_NATIONALITY_ID:55,
  EMAIL_VALIDATOR:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PROCEDURE_PRIORITY_PRIMARY:34
};
export const UAE_COUNTRY_CODE={
  COUNTRY_CODE_ID:224,
  SHORT_CODE:'AE',
  COUNTRY:'UNITED ARAB EMIRATES',
  COUNTRY_CODE:'+971',
  VAL:'AE +971'
}
export const timeout= {
  LOGOUT:181,//logout after idle(in seconds)
  WARNING:30,//ping(in seconds)
  IDLE:420,//idle(in seconds)
  LOGOUT_T:179
}
  export const patientData   = {
    PATIENT_NO_SUFFIX : 'MRN'
  };
  export const DoctorCategory={
    GRADE_1:9,
    GRADE_2:10,
    GRADE_3:11
  }
  export const extractedToothProcedureId =[5]
export const consultation_cpt=['9','9.01','10','10.01','11','11.01']
export const user_type={
  doc:4,
  dental_doc:10
}
export const DiagnoDropDowns={
  LEVEL:4,
  TYPE:9,
  TYPE_NABIDH:15
}

