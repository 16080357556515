import { HttpClient } from '@angular/common/http';
import { of } from "rxjs";
import { environment } from "@env/environment";
import { map, catchError, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var cpt_path = environment.API_ENDPOINT + 'CurrentProceduralCode/listCurrentProceduralCodeforTreatment';
var NewBillService = /** @class */ (function () {
    function NewBillService(http) {
        this.http = http;
    }
    NewBillService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    NewBillService.prototype.getVisitListByDate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getVisitListByDate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getVisitListByDate'));
        }));
    };
    NewBillService.prototype.getIncoiceList = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/listinvoices', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getIncoiceList'));
        }));
    };
    NewBillService.prototype.saveInvestigationByCashier = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/saveInvestigationByCashier', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveInvestigationByCashier'));
        }));
    };
    NewBillService.prototype.deleteInvestigationByCashier = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/deleteInvestigationByCashier', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('deleteInvestigationByCashier'));
        }));
    };
    NewBillService.prototype.get_lab_investigation = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getLabInvestigation', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('get_lab_investigation'));
        }));
    };
    NewBillService.prototype.getBillByAssessment = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getBillByAssessment', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getBillByAssessment'));
        }));
    };
    NewBillService.prototype.getInvoiceDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getBill', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getInvoiceDetails'));
        }));
    };
    NewBillService.prototype.getPatientDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getPatientDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    NewBillService.prototype.savePatientBill = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/savePatientBill', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('savePatientBill'));
        }));
    };
    NewBillService.prototype.finalizePatientBill = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/finalizePatientBill', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('finalizePatientBill'));
        }));
    };
    NewBillService.prototype.getPendingAmount = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getPendingAmount', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPendingAmount'));
        }));
    };
    NewBillService.prototype.getBillDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getBill', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getBillDetails'));
        }));
    };
    NewBillService.prototype.updateBilling = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/updateBilling', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateBilling'));
        }));
    };
    NewBillService.prototype.getReceiptsByPatient = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getReceiptsByPatient', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReceiptsByPatient'));
        }));
    };
    NewBillService.prototype.getReceiptsList = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getReceiptsList', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReceiptsList'));
        }));
    };
    NewBillService.prototype.getReceiptDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getReceiptDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReceiptDetails'));
        }));
    };
    NewBillService.prototype.editReceipt = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/editReceipt', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('editReceipt'));
        }));
    };
    NewBillService.prototype.getReceiptsDetails = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getReceiptsDetails', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReceiptsDetails'));
        }));
    };
    NewBillService.prototype.invoiceWriteOff = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/invoiceWriteOff', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('invoiceWriteOff'));
        }));
    };
    NewBillService.prototype.finalizeReceipt = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/finalizeReceipt', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('finalizeReceipt'));
        }));
    };
    NewBillService.prototype.getInsurancePayerList = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(environment.API_ENDPOINT + 'Querys/getInsurancePayerList', JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    NewBillService.prototype.getNetworkList = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(environment.API_ENDPOINT + 'Querys/getNetworkList', JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    NewBillService.prototype.getTpaList = function (term) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(environment.API_ENDPOINT + 'Querys/getTpaList', JSON.stringify({ search_text: term, limit: 50, start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    NewBillService.prototype.saveLabInvestigationResults = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/saveLabInvestigationResults', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('saveLabInvestigationResults'));
        }));
    };
    NewBillService.prototype.getLabInvestigationResults = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getLabInvestigationResults', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getLabInvestigationResults'));
        }));
    };
    NewBillService.prototype.getPatientCptRate = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getPatientCptRate', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientCptRate'));
        }));
    };
    NewBillService.prototype.checkPreviousBill = function (opData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/checkPreviousBill', JSON.stringify(opData)).pipe(tap(function (result) {
            return catchError(_this.handleError('checkPreviousBill'));
        }));
    };
    // save files
    NewBillService.prototype.saveCPTFiles = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/saveCPTFiles', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('postData'));
        }));
    };
    NewBillService.prototype.getCPTFiles = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/getCPTFiles', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('postData'));
        }));
    };
    NewBillService.prototype.getRefundReceiptListByDate = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Refund/getRefundReceiptListByDate', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getRefundReceiptListByDate'));
        }));
    };
    NewBillService.prototype.getRefundReceiptDetails = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Refund/getRefundReceiptDetails', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getRefundReceiptDetails'));
        }));
    };
    NewBillService.prototype.removeCPTFiles = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'NewBill/removeCPTFiles', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('postData'));
        }));
    };
    NewBillService.prototype.saveRefundDetails = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'Refund/saveRefundDetails', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('postData'));
        }));
    };
    NewBillService.prototype.cptsearch = function (term, department) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .post(cpt_path, JSON.stringify({ procedure_code_category: department, search_text: term, limit: 50, current_procedural_code_id: '0', start: 1 })).pipe(map(function (response) { return response['data']; }));
    };
    NewBillService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    NewBillService.ngInjectableDef = i0.defineInjectable({ factory: function NewBillService_Factory() { return new NewBillService(i0.inject(i1.HttpClient)); }, token: NewBillService, providedIn: "root" });
    return NewBillService;
}());
export { NewBillService };
