import { Component, OnInit,ViewChild } from '@angular/core';
import { routerTransition } from '@app/router.animations';
import { NotifierService } from 'angular-notifier';
import {NgxLoadingComponent} from "ngx-loading";
import { ReferralDoctorService} from "@shared/services/referral-doctor.service";

@Component({
  selector: 'app-referral-doctor',
  templateUrl: './referral-doctor.component.html',
  styleUrls: ['./referral-doctor.component.scss']
})
export class ReferralDoctorComponent implements OnInit {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  private notifier: NotifierService;
  public  user_rights : any = {};
  public  user : any = {};
  public now = new Date();
  public date:any;
  public loading = false;
  public referraldoctor_list : any =[];
  public referraldoctor_type_list : any =[];
  public referraldoctor_data={
    referraldoctor_id :0,
    referraldoctor_name :'',
    refferraldoctor_clinitian_id :'',
    referraldoctor_stat :1,
    // department_type :'',
  }
  public search_ref ='';
  public start: any;
  public limit: any;
  public search: any;
  public status: any;
  index: any;
  public collection: any = '';
  page = 1;
  p = 50;
  public licence_mask = [/[A-Z]/, /[A-Z]/, /[A-Z]/, '-', /[A-Z]/, '-', /[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];

   constructor(public rest:ReferralDoctorService,notifierService: NotifierService) {
     this.notifier = notifierService;
   }



  ngOnInit() {
    this.getReferralDoctorList();
  }
  clear(){
    this.referraldoctor_data={
      refferraldoctor_clinitian_id :'',
      referraldoctor_id :0 ,
      referraldoctor_name :'',
      referraldoctor_stat:1,

    }
  }
  public selectStatus(val) {
    this.referraldoctor_data.referraldoctor_stat = val;

  }
  public saveReferral(){

    if(this.referraldoctor_data.referraldoctor_name == "")
    {
      this.notifier.notify( 'error', 'Please Enter Referral Doctor Name!' );
      return;
    } else if(this.referraldoctor_data.refferraldoctor_clinitian_id == "")
    {
      this.notifier.notify( 'error', 'Please Enter Clinitian Id !' );
      return;
    }
    else if(this.referraldoctor_data.refferraldoctor_clinitian_id != "")
    {
      let empty = /_/gi;
      if (this.referraldoctor_data.refferraldoctor_clinitian_id.search(empty)>1){
        this.notifier.notify('error', 'Please Enter Proper Licence No.!');
        return;
      }
      else {
        let Dat_array = this.referraldoctor_data.refferraldoctor_clinitian_id.split('-')
        if (Dat_array.length>3){
          this.notifier.notify('error', 'Please Enter Proper Licence No.!');
          console.log("2");
          return;
        }
        if (Dat_array[0].length>3){
          this.notifier.notify('error', 'Please Enter Proper Licence No.!');
          console.log("3");
          return;
        }
        if (Dat_array[1].length>1){
          this.notifier.notify('error', 'Please Enter Proper Licence No.!');
          console.log("4");
          return;
        }
        if (Dat_array[2].length !=8){
          this.notifier.notify('error', 'Please Enter Proper Licence No.!');
          console.log("5");
          return;
        }
      }
    }
    this.rest.saveReferral(this.referraldoctor_data).subscribe((result) => {

      if (result['status'] === 'Success') {

        if (result['status'] === 'Success') {
          this.notifier.notify( 'success', result['msg'] );
        // }
        this.clear();
        this.getReferralDoctorList();

      } else {
        if (result.response) {

          this.notifier.notify( 'error', result.response );
        } else {
          this.notifier.notify( 'error', 'Failed  to saved the details!' );
        }

      }
          // this.notifier.notify( 'success', result['msg'] );

      }

    }, (err) => {

    });
  }






  public getReferralDoctorList(page=0,stat =0)
  {
    const limit = 100;
    this.status = '';
    const starting = page * limit;
    this.start = starting;
    this.limit = limit;
    if (stat ==1){
      this.start =0;
    }
    const postData = {
      start : this.start,
      limit : this.limit,
      search_text:this.search_ref
    };

    this.rest.listReferralDoctor(postData).subscribe((response) => {
      if(response['status'] === 'Success') {

        this.referraldoctor_list = response['data'];
        // this.collection=response.total_count;
        this.collection = response['total_count'];
        // this.status=response.status
        const i = this.referraldoctor_list.length;
        this.index = i + 5;
      }

    });
  }

  public editReferralDoctor(data) {

    this.referraldoctor_data.refferraldoctor_clinitian_id = data.REFERRAL_DOCTOR_CLINITIAN_ID;
    this.referraldoctor_data.referraldoctor_stat = data.REFERRAL_DOCTOR_STATUS;
    this.referraldoctor_data.referraldoctor_id = data.REFERRAL_DOCTOR_ID;
    this.referraldoctor_data.referraldoctor_name = data.REFERRAL_DOCTOR_NAME;
    window.scrollTo(0, 0);
  }
  public clear_search() { if (this.search_ref !== '') {
    // this.clearCPT();
    this.search_ref='';
    this.getReferralDoctorList(1);
    this.status = '';
  }
  }



}
