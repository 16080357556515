import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        "ignoreLoadingBar": 'true'
    })
};
var OpVisitService = /** @class */ (function () {
    function OpVisitService(http) {
        this.http = http;
    }
    OpVisitService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    OpVisitService.prototype.getOpDropdowns = function () {
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/options', null).pipe(map(this.extractData));
    };
    OpVisitService.prototype.getPatientDetails = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getPatientDetails', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.getDrByDateDept = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getDrByDateDept', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.getlocation = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getlocation', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.gettype = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/gettype', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.getservice = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getservice', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.getSocialStates = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getSocialStates', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getSocialStates'));
        }));
    };
    OpVisitService.prototype.getsource = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getsource', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    //getVisitListByDate
    OpVisitService.prototype.getVisitListByDate = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getVisitListByDate', JSON.stringify(postData), httpOptions).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    OpVisitService.prototype.getCPTBySites = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getCPTBySites', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getPatientDetails'));
        }));
    };
    //updateInsuranceDetails
    OpVisitService.prototype.updateInsuranceDetails = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/updateInsuranceDetails', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateInsuranceDetails'));
        }));
    };
    OpVisitService.prototype.updateCompanyDetails = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/updateCompanyDetails', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('updateCompanyDetails'));
        }));
    };
    OpVisitService.prototype.addVisit = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/addVisit', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('addVisit'));
        }));
        //
    };
    OpVisitService.prototype.getReferralDoctorList = function () {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'ReferralDoctor/getReferralDoctorList', null).pipe(tap(function (result) {
            return catchError(_this.handleError('getReferralDoctorList'));
        }));
    };
    OpVisitService.prototype.getProceduresByVisitId = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getProceduresByVisitId', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getProceduresByVisitId'));
        }));
    };
    OpVisitService.prototype.getReferrenceByVisitId = function (postData) {
        var _this = this;
        return this.http.post(environment.API_ENDPOINT + 'OpVisitEntry/getReferrenceByVisitId', JSON.stringify(postData)).pipe(tap(function (result) {
            return catchError(_this.handleError('getReferrenceByVisitId'));
        }));
    };
    OpVisitService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    OpVisitService.ngInjectableDef = i0.defineInjectable({ factory: function OpVisitService_Factory() { return new OpVisitService(i0.inject(i1.HttpClient)); }, token: OpVisitService, providedIn: "root" });
    return OpVisitService;
}());
export { OpVisitService };
