import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientSearchComponent } from './patient-search.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule }  from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ExportAsModule } from 'ngx-export-as';
import {SelectDropDownModule} from "ngx-select-dropdown";
@NgModule({
  declarations: [PatientSearchComponent],
  exports: [
    PatientSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxLoadingModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgbPaginationModule,
    ExportAsModule,
    SelectDropDownModule,
    NgbModule
  ]
})
export class PatientSearchModule { }
